import React from 'react'
import './App.css'
import styled from 'styled-components'
import Sidebar from './components/Sidebar'
import InstallHeader from './components/InstallHeader'
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom'
import ErrorPage from "./components/ErrorPage"
import PricingPage from "./components/PricingPage"

const padding = '4em'

const StyledDiv = styled.div`
  display: flex;

  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
`

const StyledRightbar = styled.div`
  padding: ${padding};
  width: 50%;

  @media only screen and (max-width: 1100px) {
    width: 85%;
    margin: 0 auto;
    padding: 1em;
  }

  .text-container {

    @media only screen and (min-width: 1100px) {
      position: relative;
      top: 50%;
      transform: translateY(calc(-50% - ${padding}));
    }
  }

  p {
    font-size: 24px;
  }
`

function App() {
  return (
    <Router basename="/">
      <div>
        {/*<InstallHeader/>*/}
        <StyledDiv>
          <Sidebar/>
          <StyledRightbar>
            <div className="text-container">
              <Routes>
                <Route path="/" element={
                  <div>
                    <h1>About Me</h1>
                    <p style={{ lineHeight: '35px' }}>
                      I'm an AI and GPT consultant and solutions architect. I help companies develop and implement AI solutions for their businesses and products. I can
                      help you create proprietary models based on your data, or find new ways to intelligently integrate
                      AI workflows into your business. Visit the <Link to="/pricing">pricing</Link> page to learn more.
                    </p>
                  </div>
                }/>
                <Route path="pricing" element={<PricingPage/>}/>
              </Routes>
              {/*<h1>"imagination is more important than knowledge" - <br/>Albert Einstein</h1>*/}
            </div>
          </StyledRightbar>
        </StyledDiv>
      </div>
    </Router>
  )
}

export default App
