import React from 'react'
import styled from 'styled-components'
import RafayPic from '../../assets/images/rafay-pic.jpg'
import {Link} from "react-router-dom"

const StyledDiv = styled.div`
    width: 38%;
    height: 100vh;
    background: #1e272e;
    
    @media only screen and (max-width: 1100px) {
      width: 100%;
      height: 100%;
    }
    
    .container {
        top: 15%;
        position: relative;
        text-align: center;
        color: white;
            
        img {
            width: 150px;
            border-radius: 50%;
            border: 2px solid white;
        }
        
        ul {
          list-style: none;
          padding: 0;
          
            li {
              text-align: center;
              margin-bottom: 1em;
              font-size: 24px;
              
              a {
                  text-decoration: none;
                  color: white;
                  
                  &:hover {
                    text-decoration: underline;
                  }
              }
            }
        }
    }
`

export default function Sidebar() {

  return (
    <StyledDiv>
      <div className="container">
        <img src={RafayPic} alt="A selfie picture of Rafay"/>
        <h1>Rafay</h1>
        <div>
          <ul>
            <li><Link to="/">about</Link></li>
            <li><Link to="/pricing">pricing</Link></li>
            <li><a target="_blank" href="https://pairdot.com">pairdot.com</a><br/><p
              style={{fontSize: 14, margin: '5px 0'}}><i>decentralized file sharing</i></p></li>
            <li><a target="_blank" href="https://codara.io">codara.io</a><br/><p
              style={{fontSize: 14, margin: '5px 0'}}><i>AI code review</i></p></li>
            <li><a target="_blank" href="https://www.linkedin.com/in/rafaychoudhury/">linkedIn</a></li>
            <li><a href="mailto:rafay826@gmail.com">email</a></li>
            {/*<li><a target="_blank" href="https://rafaymusic.com">music</a></li>*/}
            {/*<li><a target="_blank" href="https://fywave.com">streetwear</a></li>*/}
            {/*<li><a target="_blank" href="https://page.kiwi">Page Kiwi</a></li>*/}
          </ul>
        </div>
      </div>
    </StyledDiv>
  )
}
