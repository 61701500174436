import React from 'react'
import styled from 'styled-components'
import { FaCheckCircle } from "react-icons/fa";

// Define the types for your pricing plan
type PlanType = {
  name: string;
  price: string;
  features: string[];
  buttonLabel: string;
  buttonVariant: 'primary' | 'secondary';
};

// Styled components
const PageContainer = styled.div`
  font-family: 'Arial', sans-serif;
  text-align: center;
`

const Title = styled.h1`
  color: #333;
`

const PlanContainer = styled.div`
  justify-content: center;
  margin: 20px 0;
`

const Plan = styled.div<{ emphasized?: boolean }>`
  border: ${(props) => (props.emphasized ? '2px solid #e9e9e9' : '1px solid #ccc')};
  padding: 20px;
  margin: 5% 0;
  flex: 1;
  display: flex;
  text-align: left;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
`

const PlanTitle = styled.h2`
  color: #333;
`

const Price = styled.div`
  font-size: 24px;
  color: ${(props) => props.color || '#333'};
`

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
`

const Feature = styled.li`
  margin: 10px 0;
  margin-left: 10px;
`

const Button = styled.a<{ variant: 'primary' | 'secondary' }>`
  padding: 10px 20px;
  border: none;
  background-color: ${(props) => (props.variant === 'primary' ? '#4e9af1' : '#ccc')};
  color: white;
  cursor: pointer;
  text-decoration: none;
`

// Pricing plans data
const plans: PlanType[] = [
  {
    name: 'Basic',
    price: '$200/hr',
    features: ['AI consultation', 'project guidance', 'model training'],
    buttonLabel: 'Get Started',
    buttonVariant: 'primary',
  },
  {
    name: 'Pro',
    price: '$10k+/mo',
    features: ['AI application integration', 'AI architectures solutions', 'custom ai endpoints', 'model training', 'support'],
    buttonLabel: 'Get Started',
    buttonVariant: 'primary',
  },
]

// The main component
const PricingPage: React.FC = () => {
  return (
    <PageContainer>
      <Title>Pricing Packages:</Title>
      <PlanContainer>
        {plans.map((plan) => (
          <Plan key={plan.name} emphasized={plan.price !== 'Free'}>
            <PlanTitle>{plan.name}</PlanTitle>
            <Price>{plan.price}</Price>
            <FeatureList>
              {plan.features.map((feature, index) => (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                  <FaCheckCircle color="green"/>
                  <Feature key={index}>{feature}</Feature>
                </div>
              ))}
            </FeatureList>
            <Button variant={plan.buttonVariant} href="mailto:rafay@pairdot.com">{plan.buttonLabel}</Button>
          </Plan>
        ))}
      </PlanContainer>
    </PageContainer>
  )
}

export default PricingPage